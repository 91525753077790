html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,h1,h2,h3,h4,h5,h6,blockquote,p,pre,code,dl,dd,ol,ul,figure,hr,fieldset,legend {
  margin: 0;
  padding: 0;
}
*,*::before,*::after {
  box-sizing: border-box;
}
main {
  display: block;
}
h1,h2,h3,h4,h5,h6 {
  font-size: inherit;
}
strong {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border-style: none;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
input,select,textarea {
  margin: 0;
}
input {
  overflow: visible;
}
select {
  text-transform: none;
}
[type='checkbox'],[type='radio'] {
  padding: 0;
  box-sizing: border-box;
}
[type='number']::-webkit-inner-spin-button,[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
[type='search']::-webkit-search-cancel-button,[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
html {
  font-size: 62.5%;
}
img,svg {
  display: block;
}
body {
  font: 1.6rem/1.6 'Lato', sans-serif;
  color: #3d3d41;
}
a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
li a {
  vertical-align: top;
}
#__next {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ant-modal-priority {
  z-index: 1053 !important;
}
.ant-notification {
  z-index: 1054 !important;
}
.ant-menu-submenu-popup {
  position: fixed !important;
}
.ant-menu-item > a {
  white-space: normal;
}
.ant-select-dropdown-menu-item  {
  white-space: normal;
}
@media (max-width: 960px) {
  .ant-select-search__field,
  input.ant-input {
    font-size: 16px !important;
  }
}
@media (max-width: 596px) {
  .ant-calendar-input-wrap {
    height: 38px;
  }
  .ant-calendar-input {
    font-size: 16px;
    height: 26px;
  }
}
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #003DA6;
  position: fixed;
  z-index: 1031;
  top: 72px;
  left: 0;
  width: 100%;
  height: 2px;
}
@media (min-width: 992px) {
  #nprogress .bar {
    top: 80px;
  }
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogess .bar {
  position: absolute;
}
.sanitized [data-oembed-url] > div {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
}
.sanitized [data-oembed-url] > div iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.sanitized hr {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: solid 1px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.sanitized p {
  padding-bottom: 4px;
  padding-top: 4px;
}
.sanitized td {
  padding: 4px !important;
}
.sanitized li a {
  vertical-align: baseline;
}
.sanitized a {
  color: #003DA6;
  font-weight: 900;
}
.sanitized a:focus {
  outline: none;
  color: #EA002A;
  box-shadow: none !important;
}
@media (min-width: 576px) {
  .sanitized .float-left {
    float: left;
    margin-right: 1rem;
  }

  .sanitized .float-right {
    float: right;
    margin-left: 1rem;
  }
}
.is-fixed {
  position: fixed;
}
/* Visible to screen readers only */
.u-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.u-sr-only-focusable:active,
.u-sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

@media print {
  body {
    font: 1.4rem/1.6 'Lato', sans-serif;
    letter-spacing: 0.4px;
    color: #1d1d22;
  }
  h1, h2, h3, h4, h5, h6, p {
    color: #000 !important;
    orphans: 3;
    widows: 3;
    display: block !important;
    width: 100%;
    border: 0;
    padding: 0;
    float: none;
    position: static;
    overflow: visible;
  }
  h1, h2, h3, h4, h5, h6 {
    -webkit-column-break-inside: avoid !important;
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }
  td, th,
  strong {
    color: #000 !important;
  }
  li,
  .sanitized div {
    color: #000;
  }
  .sanitized {
    font-size: 1.4rem;
  }
}
